export default class DateTimeHelper {
  /** 表示用日付フォーマット(yyyy/MM/dd) */
  REGEX_DISPLAY_DATE_FORMAT = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

  /**
   * Constructor
   * @param {String} forrmatStr Format ex:YYYY/MM/DD hh:mm:ss
   */
  constructor(forrmatStr) {
    Object.defineProperty(this, "_defaultFormat", {
      value: "YYYY/MM/DD hh:mm:ss.SSS",
    });
    if (forrmatStr === null || forrmatStr === undefined) {
      this.forrmatStr = this._defaultFormat;
    } else {
      this.forrmatStr = forrmatStr;
    }
  }

  getFormatStr() {
    return this.forrmatStr;
  }

  /**
   * Date形式の日付をフォーマットを指定して文字列を返します。
   * @param {Date} date
   * @returns {String}
   */
  toStringDate(formatString, date) {
    const yearStr = date.getFullYear();
    //getMonthは0～12の戻り値
    //("0" + i).slice(-2);
    const monthStr = ("0" + (1 + date.getMonth())).slice(-2);
    const dayStr = ("0" + date.getDate()).slice(-2);
    const hourStr = ("0" + date.getHours()).slice(-2);
    const minuteStr = ("0" + date.getMinutes()).slice(-2);
    const secondStr = ("0" + date.getSeconds()).slice(-2);
    const milliSecondStr = ("00" + date.getMilliseconds()).slice(-3);

    let replaceStr = formatString.replace(/YYYY/g, yearStr);
    replaceStr = replaceStr.replace(/MM/g, monthStr);
    replaceStr = replaceStr.replace(/DD/g, dayStr);
    replaceStr = replaceStr.replace(/hh/g, hourStr);
    replaceStr = replaceStr.replace(/mm/g, minuteStr);
    replaceStr = replaceStr.replace(/ss/g, secondStr);
    replaceStr = replaceStr.replace(/SSS/g, milliSecondStr);

    return replaceStr;
  }

  /**
   * 渡されたJSTをUTCに変換
   * @param {}} formatString
   * @param {*} date
   * @returns
   */
  toStringUTCDate(formatString, date) {
    const yearStr = date.getUTCFullYear();
    //getMonthは0～12の戻り値
    //("0" + i).slice(-2);
    const monthStr = ("0" + (1 + date.getUTCMonth())).slice(-2);
    const dayStr = ("0" + date.getUTCDate()).slice(-2);
    const hourStr = ("0" + date.getUTCHours()).slice(-2);
    const minuteStr = ("0" + date.getUTCMinutes()).slice(-2);
    const secondStr = ("0" + date.getUTCSeconds()).slice(-2);
    const milliSecondStr = ("00" + date.getUTCMilliseconds()).slice(-3);

    let replaceStr = formatString.replace(/YYYY/g, yearStr);
    replaceStr = replaceStr.replace(/MM/g, monthStr);
    replaceStr = replaceStr.replace(/DD/g, dayStr);
    replaceStr = replaceStr.replace(/hh/g, hourStr);
    replaceStr = replaceStr.replace(/mm/g, minuteStr);
    replaceStr = replaceStr.replace(/ss/g, secondStr);
    replaceStr = replaceStr.replace(/SSS/g, milliSecondStr);

    return replaceStr;
  }

  /**
   * 渡されたUTCをJSTに変換
   * @param {}} formatString
   * @param {*} date
   * @returns
   */
  toStringJST2UTCDate(formatString, date) {
    const dateJst = new Date(date);
    dateJst.setMinutes(dateJst.getMinutes() - date.getTimezoneOffset());
    const yearStr = dateJst.getFullYear();
    //getMonthは0～12の戻り値
    //("0" + i).slice(-2);
    const monthStr = ("0" + (1 + dateJst.getMonth())).slice(-2);
    const dayStr = ("0" + dateJst.getDate()).slice(-2);
    const hourStr = ("0" + dateJst.getHours()).slice(-2);
    const minuteStr = ("0" + dateJst.getMinutes()).slice(-2);
    const secondStr = ("0" + dateJst.getSeconds()).slice(-2);
    const milliSecondStr = ("00" + dateJst.getMilliseconds()).slice(-3);

    let replaceStr = formatString.replace(/YYYY/g, yearStr);
    replaceStr = replaceStr.replace(/MM/g, monthStr);
    replaceStr = replaceStr.replace(/DD/g, dayStr);
    replaceStr = replaceStr.replace(/hh/g, hourStr);
    replaceStr = replaceStr.replace(/mm/g, minuteStr);
    replaceStr = replaceStr.replace(/ss/g, secondStr);
    replaceStr = replaceStr.replace(/SSS/g, milliSecondStr);

    return replaceStr;
  }

  /**
   * 現在日付を既定のフォーマットの形式の文字列を返します。
   * @returns {String}
   */
  toStringNowDate() {
    return this.toStringDate(this.getFormatStr(), this.convertJstDateTime(new Date()));
  }

  /**
   * 日本時間の現在日付をyyyyMMddの形式で返します。
   * @returns {String}
   */
  toStringNowYYYYMMDD() {
    return this.toStringDate("YYYYMMDD", this.convertJstDateTime(new Date()));
  }

  /**
   * 日本時間の現在日付をyyyy/MM/ddの形式で返します。
   * @returns {String}
   */
  toStringNowYYYY_MM_DD1() {
    return this.toStringDate("YYYY/MM/DD", this.convertJstDateTime(new Date()));
  }

  /**
   * 日本時間の現在日付をyyyy-MM-ddの形式で返します。
   * @returns {String}
   */
  toStringNowYYYY_MM_DD2() {
    return this.toStringDate("YYYY-MM-DD", this.convertJstDateTime(new Date()));
  }

  /**
   * 渡されたUTC日付を日本時間へ変換して返します。
   * @returns {Date}
   */
  convertJstDateTime(utcDateTime) {
    return new Date(utcDateTime + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000);
  }
  /**
   * 現在日時をUTCで取得、yyyy/MM/ddへ変換して返します。
   * @returns {Date}
   */
  convertJstYYYYMMDD() {
    const date = new Date().toISOString().split("T")[0].replaceAll("-", "/");
    let dateString = new Date().toISOString().split("T")[1];
    dateString = dateString.substring(0, 12);
    return date + " " + dateString;
  }

  validDate(strDate, prefix) {
    if (strDate != undefined && strDate != "" && strDate.length == 10) {
      var ymd = strDate.split(prefix);
      if (new Date(ymd[0] + "-" + ymd[1] + "-" + ymd[2]) != "Invalid Date") {
        if (ymd[1] == "02" && new Date(ymd[0], 2, 0).getDate() < parseInt(ymd[2])) {
          return false;
        }
        return true;
      }
      return false;
    }
  }

  validTime(strTime, prefix) {
    if (strTime != undefined && strTime != "" && strTime.length == 5) {
      var time = strTime.split(prefix);
      if ((time[0] + ":" + time[1]).match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/) !== null) {
        return true;
      }
      return false;
    }
  }

  /**
   * パラメータがない場合今の日時を返却
   * パラメータがあったらyyyy/mm/dd hh24:m:ss.sssで返却
   */
  convertJST(strDate) {
    if (strDate == undefined || strDate == null || strDate == "") {
      var now = new Date();
      return this.toStringDate(this.getFormatStr(), now);
    }
    return this.toStringDate(this.getFormatStr(), new Date(strDate));
  }

  /**
   * 渡されたJSTをUTCに変換
   * パラメータがないであれば今の日時をUTCに変換
   */
  convertUTC(strDate) {
    if (strDate == undefined || strDate == null || strDate == "") {
      var now = new Date();
      return this.toStringUTCDate(this.getFormatStr(), now);
    }
    return this.toStringUTCDate(this.getFormatStr(), new Date(strDate));
  }

  /**
   * 渡されたUTCをJSTに変換
   * @param {*} strDate
   * @returns
   */
  convertUTC2JST(strDate) {
    return this.toStringJST2UTCDate(this.getFormatStr(), new Date(strDate));
  }

  /**
   * 日付存在チェック
   * @param {*} strDate yyyy/MM/dd形式
   * @returns true: 存在する場合、false: 存在しない場合、またはパラメータがyyyy/MM/dd以外の場合
   */
  isDate(strDate) {
    // 形式チェック
    if (!this.isDisplayDateFormat) {
      // 日付フォーマット出ない場合はfalseを返却する
      return false;
    }
    // 引数を年月日に分割
    let yyyy = strDate.split("/")[0];
    let mm = strDate.split("/")[1] - 1;
    let dd = strDate.split("/")[2];

    // 引数でdateクラスを生成
    let date = new Date(strDate);

    // 生成されたdateクラスとパラメータを比較する。
    if (date.getFullYear() == yyyy && date.getMonth() == mm && date.getDate() == dd) {
      // 存在する日付の場合
      return true;
    } else {
      // 同じでない場合。（存在しない日付の場合）
      return false;
    }
  }

  /**
   * 画面表示の日付フォーマット(yyyy/MM/dd)かをチェックします。
   * @param {*} checkStr チェック対象
   * @returns true: yyyy/MM/ddの場合 false: それ以外
   */
  isDisplayDateFormat(checkStr) {
    // 日付の正規表現（yyyy/MM/dd）
    if (this.REGEX_DISPLAY_DATE_FORMAT.test(checkStr)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 当日以降日付であるかをチェックします。
   * @param {*} chkDate チェック対象日付（yyyy-MM-dd）
   * @returns 結果(true: 当日以降 false: それ以外)
   */
  isDateAfterTheDay(chkDate) {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return today <= new Date(chkDate);
  }

  /**
   * タイムゾーンの名称を取得
   * @returns
   */
  getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * タイムゾーン情報付きのISO 8601拡張形式処理
   */
  toISOStringWithTimezone(date) {
    const pad = function (str) {
      return ("0" + str).slice(-2);
    };
    const year = date.getFullYear().toString();
    const month = pad((date.getMonth() + 1).toString());
    const day = pad(date.getDate().toString());
    const hour = pad(date.getHours().toString());
    const min = pad(date.getMinutes().toString());
    const sec = pad(date.getSeconds().toString());
    const tz = -date.getTimezoneOffset();
    const sign = tz >= 0 ? "+" : "-";
    const tzHour = pad((tz / 60).toString());
    const tzMin = pad((tz % 60).toString());

    return `${year}-${month}-${day}T${hour}:${min}:${sec}${sign}${tzHour}:${tzMin}`;
  }

  dateCalc(date) {
    const dt = this.toISOStringWithTimezone(new Date(date));
    let year = dt.substr(0, 4);
    let month = dt.substr(5, 2);
    let day = dt.substr(8, 2);
    return year + "-" + month + "-" + day;
    // return dt.toISOString().substr(0, 10);
  }

  dateCalcYyyyMM(date) {
    const dt = this.toISOStringWithTimezone(new Date(date));
    return dt.substr(0, 7);
  }
}

export const dateTimeHelper = new DateTimeHelper();
